.footer {
  box-shadow: 0 -2px 6px 1px #80808057;
  min-height: 80px;
  width: 100%;
  @media screen and (max-width: 900px) {
    min-height: 50px;
  }

  &__wrapper {
    background: url('../../../assets/images/footer-border.gif') repeat;
    height: 10px;
    margin-bottom: 20px;
    @media screen and (max-width: 900px) {
      margin-bottom: 10px;
    }
  }

  &__description {
    text-align: center !important;
  }
}
