
.teamList {
  .userFullname {
    cursor: pointer;
  }
  .ant-descriptions-item-content span {
    display: flex;
    font-display: row;
  }

  .anticon-star {
    // color: #ffd500;
    // color: #69b1ff;
    color: #318cef;
    margin-left: 5px;
  }

  .fullRole {

    @media(max-width: 450px) {
      display: none;
    }
  }

  .cutRole {
    @media(min-width: 450px) {
      display: none;
    }
  }


}