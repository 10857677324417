.settings {
  &__navigation {
    &_list {
      min-height: 100px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background: #f3f3f3;
      border-radius: 5px;
      padding: 15px;
      margin-right: 45px;
      @media screen and (max-width: 768px) {
        padding: 5px;
      }
    }
    &_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 15px!important;
      text-transform: capitalize;
      cursor: grab!important;
      @media screen and (max-width: 768px) {
        margin-top: 5px!important;
      }
    }
  }

  &__dashboard {
    &_switch {
      text-align: right;
    }
  }
}




