.profile {
  &__blockHeader {
    padding: 10px;
    background-color: #7ec1ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__info {
    &_imageContainer {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_image {
      width: 70%;
      @media screen and (max-width: 768px) {
        width: 30%;
      }
    }
  }
  &__hours {
  }
  &__bonuses {
  }
}
