.quill {
	.ql- {

		&toolbar, &container {
			border: 1px solid #d9d9d9;
		}

		&toolbar {
			background-color: #f8f8f8;
			border-radius: 8px 8px 0 0;

			button {
				width: 25px;
			}
		}

		&container {
			border-radius: 0 0 8px 8px;
		}

		&formats {
			margin-right: 5px !important;
		}

		&size {
			width: 77px !important;
		}
	}
}