// .holidays {
  .customTableWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
// }
.holidays {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}