.customEmpty, .customizedEmptyComponent {
	text-align: center;
	margin-block: 25px;

  p {
		display: flex;
		flex-direction: row;
		justify-content: center;
		margin-top: -20px;
	}
}

.customizedEmptyComponent {
	color: rgba(0, 0, 0, 0.25);
}

