.news {
  &__table {
    margin-bottom: 30px;
  }

  &__pagination {
    text-align: center;
  }
}

.whitter {
  padding-bottom: 30px;

  &__logo {
    margin-bottom: 30px;
  }
}

.cursorPointer {
  cursor: pointer;
}

.whitterItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  

  &__rating {
    height: 35px;
    width: 35px;
  }

  &__body {
    display: flex;
    flex-grow: 1;
    align-items: center;
    margin: 0 15px;
    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__date {
    margin-right: 15px;
    white-space: nowrap;
  }

  &__title {
    font-size: 12px;
    flex-grow: 1;
  }

  &__more {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__moreIcon {
    font-size: 20px;
    cursor: pointer;
    color: #08c;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__modalContainer {
    min-height: 50vh;
  }

  &__moreNews {
    margin-top: 30px;
    text-align: right;
  }

  &__modal {
    display: flex;
    align-items: center;

    &_date {
      margin-left: 20px;
    }

    &_title {
      font-size: 12px;
      font-weight: 700;
    }

    &_body {
      font-size: 12px;
      font-weight: 300;
    }
  }
}
