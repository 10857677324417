p,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-weight: 400;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
    box-sizing: border-box;
}

.ant-divider {
    margin: 15px 0!important;
}

body {
    margin: 0;
    padding: 0;
    height: calc(100vh - 182px);
    @media screen and (max-width: 900px) {
        height: calc(100vh - 117px);
    }
}

.ant-card-body {
    padding: 10px!important;
    @media screen and (max-width: 768px) {

    }
}

*::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important; //???
}

* {
    scrollbar-width: none;
    // scrollbar-height: none;
}