.birthdays {
  &__title {
    margin: 0!important;
  }

  &__monthSelector {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    justify-content: start;
    margin-bottom: 20px;

    &_title {
      color: #1890ff !important;
      margin: 0 0px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;

      .ant-picker {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 3px;

        @media (max-width: 900px) {
          margin-top: 0;
        }
      }
    }

    &_icon {
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
        color: #1890ff !important;
      }
    }

    &_inner {
      width: 186px;

      @media (max-width: 900px) {
        width: 148px;
      }
    }
  }

  &__listContainer {
    display: flex;
    justify-content: space-between;


    &_list {
      width: 100%;
      margin-bottom: 10px;

      .ant-list {
        border: none;
        box-shadow: 1px 1px 10px rgb(0 0 0 / 9%);
        border-radius: 8px;

        .ant-list-header {
          border-radius: 9px 9px 0 0;
        }

        .ant-spin-nested-loading {
          .ant-list-items {
            padding-bottom: 3px;

            .ant-list-item {
              padding: 6px 9px;
              padding-inline: 15px;
              border-block-end: 1px solid rgba(5, 5, 5, 0.02);
  
              .birthdays__listContainer_mark {
                width: 22px;
                text-align: right;
                margin-right: 8px;
                padding-right: 3px;

                mark {
                  background-color: transparent;
                  font-weight: 600;
                }
              }

              .isToday {
                background-color: rgb(215, 250, 224);
              }
            }

            .ant-list-item:last-child {
              border-block-end: 1px solid transparent;
            }
          }

        }
      }
    }

    &_name {
      color: #1890ff;
      cursor: pointer;
    }
  }
}
