.projectCollapse__wrapper {
  @media (max-width: 990px) {
    .ant-collapse-content-box {
      padding: 0px !important;
    }
  }
}



.mobileTable {
  .ant-collapse-item {
    &:hover, &:active {
      // background-color: #f6f6f6;     
      background-color: #efefef 
    }
  }

  .ant-collapse-header {
    @media(max-width: 450px) {
      //padding: 12px 16px;
      padding: 0 !important;

      .miniHeader__project {
        grid-template-rows: 25px 40px;
        gap: 15px;

        .firstRow {
          padding: 12px 0 0 16px;
        }
  
        .secondRow {
          padding: 0 0 12px 16px;
        }
  
  
        .arrowButton {
          height: 100% !important;
          padding: 3px 8px 3px 17px!important;
  
          box-shadow: -5px 1px 10px -4px #8282820f;
          border-radius: 0 !important;
  
          transition-property: background-color, box-shadow, padding;
          transition-timing-function: ease-out;
          transition-duration: 400ms;
  
          
          &:hover {
            background-color: #fcfcfc !important;
            box-shadow: -5px 1px 10px -4px #8282822e !important;
            padding: 3px 5px 3px 15px !important;
          }
        }
      }

    }


  
    .miniHeader {
      // color: #4c4c4c;
      // // display: flex;
      // // flex-direction: column;
      // display: grid;
      // grid-template-columns: 6fr 80px;
      // // grid-template-rows: 2fr 3fr;
      // grid-template-rows: 15px 30px;

      // @media(max-width: 320px) {
      //   grid-template-columns: 6fr 50px;
      // }

      &__project {
        color: #4c4c4c;
        // display: flex;
        // flex-direction: column;
        display: grid;
        grid-template-columns: 6fr 80px;
        // grid-template-rows: 15px 30px;
        grid-template-rows: minmax(15px, max-content) minmax(30px, max-content);
  
        @media(max-width: 320px) {
          grid-template-columns: 6fr 50px;
        }

        .firstRow {
          grid-column: 1/1;
          grid-row: 1/1;
  
          font-size: 11px;
          display: flex;
          flex-direction: row;
          // justify-content: space-between;
          justify-content: start;
          margin: -2px 0;
          color: inherit;
  
        }
  
        .secondRow {
          grid-column: 1/1;
          grid-row: 2/2;

          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 5px;
          font-size: 15px;
          color: black;
          font-weight: 500;

          .wrapperSvg {
            display: flex;
            align-items: center;
          }

          .title {
            display: flex;
            align-items: center;
          }
        }

        .button {
          grid-column: 2/2;
          grid-row: 1/3;
          align-self: center;

          height: min-content;
          border: 1px solid transparent;

  
          border-radius: 8px;
          padding: 3px 10px;
          cursor: pointer;
      
          &:hover {
            // background-color: rgba(23,119,255,.529);
            // background-color: rgb(29 132 255 / 78%);
            // color: white;
  
            // box-shadow: 1px 1px 3px 1px #6c6a6a4f;
            // background-color: white;
  
            // animation-name: example;
            // animation-direction: alternate-reverse;
            // animation-duration: 2500ms;
  
            // background-color: white;
            // box-shadow: 1px 1px 3px 1px #6c6a6a4f;
          }    
        }

        .fullInfoButton {
          // @media((min-width: 450px) and (max-width: 990px)) {}
          width: 80px;
          box-shadow: 1px 1px 3px 1px hsl(0deg 1% 42% / 19%);
          background-color: #fcfcfc;

          transition-property: background-color, box-shadow, transform;
          transition-duration: 300ms;

          &:hover {
            background-color: white;
            box-shadow: 1px 1px 3px 1px #6c6a6a4f;
            transform: scale(1.025);
  
          }

          @media(max-width: 450px)  {
            display: none;
          }
        }
        
        .arrowButton {
          width: min-content;
          background-color: transparent;
          justify-self: end;

          @media(min-width: 450px) {
            display: none;
          }
        }
  
      }

      &__task {
        @media(max-width: 450px) {
          padding: 10px 14px;
        }

        .firstRow {
          font-size: 11px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin: -2px 0;
          color: inherit;

          .edit-button {
            background-color: #fbfbfb;
            border: 1px solid transparent;
            padding: 0 10px;
            font-size: 13px;
            border-radius: 5px;
            box-shadow: 1px 1px 4px 0px #ababab;


            transition-property: background-color, box-shadow, transform;
            transition-duration: 300ms;
  
            &:hover {
              background-color: white;
              box-shadow: 1px 1px 3px 1px hsl(0deg 0.36% 21.94% / 31%);
              transform: scale(1.025);
    
            }

          }

          .edit-icon {
            margin-top: -5px;
            svg {
              height: 15px;
              width: 15px;
              color: #7a7a7a6e;

              &:hover, &:active, &:focus-visible {
                color: #9d9b9b;
              }
            }
          }

        }

        .secondRow {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-size: 14px;

          .task-label {
            // grid-column: 1/1;
            // grid-row: 2/2;

            display: flex;
            flex-direction: row;
            justify-content: start;
            gap: 5px;
            font-size: 15px;
            color: black;
            font-weight: 500;

            .project-title {
              display: flex;
              align-items: center;
            }
          }

          .hours {
            // color: inherit; 
            letter-spacing: 0.45px;
            display: flex;
            flex-direction: row;
            color: #d0d0d0;

            font-size: 13px;
            padding: 7px 0;
            color: #b8b8b8;

          }  
        }

        @keyframes example {
          from {
            background-color: transparent;
          }
          to {
            background-color: white;
            box-shadow: 1px 1px 3px 1px #6c6a6a4f;
          }
        }

        .button-project {
          grid-column: 2/2;
          grid-row: 1/3;
          align-self: center;
          height: min-content;
          width: min-content;
          background-color: transparent;
      
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 3px 10px;
          cursor: pointer;
      
          &:hover {
            // background-color: rgba(23,119,255,.529);
            // background-color: rgb(29 132 255 / 78%);
            // color: white;

            // box-shadow: 1px 1px 3px 1px #6c6a6a4f;
            // background-color: white;

            // animation-name: example;
            // animation-direction: alternate-reverse;
            // animation-duration: 2500ms;

            background-color: white;
            box-shadow: 1px 1px 3px 1px #6c6a6a4f;
          }    
        }
      
    
      }

    }

    .ant-table-container {
      border-inline-start: none;
      border-start-start-radius: 0px;
      border-start-end-radius: 0px;

      .ant-table-content {
        table {
          border-top: none !important;
        }
      }
    }

    .ant-collapse-expand-icon {
      @media (max-width: 990px) {
        display: none !important;
      }
    }
  }

}

.mobileTable__in-table {
  @media(max-width: 990px) {
    .ant-collapse {
      border: none;
      border-radius: 0px;

      &:last-child {
        background: linear-gradient(to top,hsla(0,0%,91%,.4),#fafafa) !important;
      }

      &:first-child {// ???
        background: linear-gradient(180deg,hsla(0,0%,91%,.4),hsl(0deg 0% 98.04%)) !important;
      }
    }
  
    .ant-collapse-item:last-child, .ant-collapse-item:last-child .ant-collapse-header {
      border-radius: 0px;
      border-bottom: none;
    }
  }

}

.tasksPage__projectItem {
  .ant-collapse {
    .ant-collapse-item:first-child {
      background: linear-gradient(180deg,hsla(0,0%,91%,.4),hsl(0deg 0% 98.04%)) !important;
    }

    .ant-collapse-item:last-child {
      background: linear-gradient(to top,hsla(0,0%,91%,.4),#fafafa) !important;

    }

    .ant-collapse-header {
      padding: 14px 12px 2px;

      @media (max-width: 450px) {
        padding: 14px 12px 2px !important;

        .miniHeader__task {
          padding: 0 !important;
        }

      }
    }
  }

}

