.calendar {
  @media screen and (max-width: 768px) {

  }
  &__days {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_item {
      width: 13.5%;
      text-align: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &_item {
      width: 13%;
      text-align: center;
    }

    &_info {
      @media screen and (max-width: 440px) {
        font-size: 12px;
      }
    }
  }

  &__cells {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.cell {
  width: 13.5%;
  height: 75px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;

  @media (max-width: 450px) {
    margin-bottom: 5px;
  }

  &__day {
    color: #46728a;
    font-weight: 700;
    margin: 0;
    font-size: 40px;
    line-height: 0;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    opacity: 0.15;
    @media screen and (max-width: 1300px) {
      font-size: 30px;
    }
    @media screen and (max-width: 700px) {
      font-size: 20px;
      top: 20px;
    }
    @media (min-width: 300px) and (max-width: 380px) {
      top: 17px;
      left: 7px;
    }
    @media (max-width: 300px) {
      left: 5px;
      top: 17px;
    }
  }

  &__hours {
    position: absolute;
    top: 10px;
    right: 10px;
    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  &__hoursAmount {
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    color: #349acc;
    margin-bottom: 0;
    position: absolute;
    bottom: 10px;
    right: 10px;
    &:hover {
      transform: scale(1.1);
    }
    @media screen and (max-width: 500px) {
      font-size: 14px;
    }

    @media (max-width: 380px) {
      bottom: 7px;
      right: 7px;
    }
  }

  &__icon {
    color: darkgray !important;
  }

  &__iconHours {
    cursor: pointer;
    color: darkgray !important;

    &:hover {
      color: #349acc !important;
      transform: scale(1.1);
    }
  }

  &__event {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 25%;
    transform: translateY(-50%);
    @media screen and (max-width: 700px) {
      top: 70%;
      right: 50%;
    }

    @media (min-width: 320px) and (max-width: 380px) {
      right: 32%;
      top: 55%;
    }
     
    @media (max-width: 320px) {
      right: 26%;
      top: 54%;
    }

    &_icon {
      width: 22px;
      height: auto;
      cursor: pointer;
      margin: 0 5px;
      @media screen and (max-width: 1300px) {
        width: 17px;
      }
      @media screen and (max-width: 500px) {
        width: 13px;
      }
      @media (max-width: 380px) {
        margin: 0 2px 0 0;
      }
    }
  }
}
