.about_and_team {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
}

.summary {

  &__block {
    width: 49.5%;
    @media (max-width: 768px) {
      width: 100%;
    }

    &_header {
      background-color: #EAF8D7;
      padding: 5px 10px;
    }
  }

  .paymentTerms {
    margin-top: 15px;
  }
}

.project-about {

  @media (max-width: 450px) {
    .ant-collapse-content-box {
      padding: 0 !important;
  
      .ant-descriptions-view {
        border-radius: 0;
  
        table tr:first-child {
          background: linear-gradient(180deg,hsla(0,0%,91%,.4),hsl(0deg 0% 100%)) !important;
        }
      }
    }
  }

}

.project-wrapper-tabs {

  @media (min-width: 500px) and (max-width: 630px) {
    .ant-segmented-group {
      .ant-segmented-item {
        &:nth-child(5n+1) {
          flex: 0.6;
        }
        &:nth-child(5n+2) {
          flex: 0.4;
        }
        &:nth-child(5n+3) {
          flex: 0.85;
        }
        &:nth-child(5n+4),  &:nth-child(5n){
          flex: 0.8;
        }

        .ant-segmented-item-label {
          padding: 0 6px !important;
          p {
            padding: 5px 0px !important;
          }
        }

      }

    }
  }
  @media (max-width: 500px) {
    .ant-segmented-group {
      display: grid;
      // grid-template-columns: 1.5fr 1fr 2fr;
      // grid-template-columns: 1.3fr 1.2fr 2fr;
      grid-template-columns: repeat(12fr);

      .ant-segmented-item {
        &:nth-child(5n+1) {
          grid-column: 1/3;
        }
        &:nth-child(5n+2) {
          grid-column: 4/5; 
        }
        &:nth-child(5n+3) {
          grid-column: 6/12;

        }
        &:nth-child(5n+4) {
          grid-column: 1/6;
        }
        &:nth-child(5n){
          grid-column: 6/11;
        }
      }
    }
  }
}

.phases {

  .teamListWithButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
    }
  
    .firstColumn {
      width: 75%;

      @media (min-width: 450px) and (max-width: 550px) {
        width: 65%;
      }
      @media (max-width: 450px) {
        width: 100%;
        margin-bottom: 25px;
      }

      .teamList {
        margin-bottom: 7px;
      }

      .ant-collapse-content-box {
        padding: 0;
      }
    }
  
    .secondColumn {
      display: flex;
      flex-direction: column;
      width: 23%;
      gap: 15px;

      @media (min-width: 450px) and (max-width: 550px) {
        width: 33%;
      }
      @media (max-width: 450px) {
        width: 100%;
      }
    
      .buttons {
        display: flex;
        flex-direction: column;
        gap: 7px;
        
        @media (min-width: 360px) and (max-width: 450px) {
        // @media (max-width: 450px) {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
        }
        @media (max-width: 360px) {
          flex-direction: column;
          width: 100%;
        }

        .wrapperAddButtons {
          display: flex;
          flex-direction: column;
          gap: 7px;

          @media (max-width: 450px) {
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            .ant-btn {
              width: 100%;
            }
          }
        }
      }

      .phasesMiniList {
        display: none;
      }
    }
  
  }
  .phasesList {
    .ant-collapse {
      // border: 1px solid #eaf4df;
      border: 1px solid #e5eeda;

      .ant-collapse-item {
        border-bottom: 1px solid #e5eeda;

        .ant-collapse-header {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f6ffed;

          @media (max-width: 450px) {
            padding: 7px 10px;
          }
      
          .phaseHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            h3 {
              display: flex;
              justify-content: center;
              align-items: center;
            }
        
            .phasesButtons {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 10px;

              @media (max-width: 450px) {
                gap: 7px;

                .ant-btn {
                  padding: 2px 7px;
                  font-size: 13px;

                  span {
                    margin-inline-start: 0;
                  }

                  .addDeveloper {
                    display: none;
                  }
                }
              }
            }
          }
        }
    
        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
    
    
    .phase {
      // border: 1px solid rgb(217, 217, 217);
      
    }
  }
}



.infrastructure {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;

  @media(max-width: 650px) {
    flex-direction: column;

  }

  .infrastructureBlock, .commentsBlock{
    display: flex;
    flex-direction: column;
    width: 47%;

    @media(max-width: 650px) {
      width: 100%;
    }

    .alertWrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .item {
      display: flex;
      flex-direction: row;

      p {
        width: 15%
      }
    }

    table {
      thead {
        display: none;
      }
      tr td {
        padding: 7px 10px;
      }
    }
  }

  .infrastructureBlock {
    .ant-collapse-content-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  .commentsBlock {
    .ant-card {
      margin-top: 22px;

      .ant-card-head {
        background-color: #eaf8d7;
  
        .wrapperButtons {
          display: flex;
          flex-direction: row;
          justify-content: end;
          gap: 6px;
      
          .ant-btn {
            border: none;
            padding: 5px 10px;
            border-radius: 0;
            transition-property: all;
            transition-duration: 300ms;
            // color: #cfcfcf;
            background-color: transparent;
            // color: #a5a5a5;
            color: #0000002b;
      
            &:hover {
              // color: #1777ff;
              // background-color: white;
              color: #686868;
              background-color: #ffffffa3;
              // box-shadow: 1px 1px 4px 3px #0101011f;
              box-shadow: 1px 1px 5px 1px rgba(1,1,1,.1);
              transform: scale(1.07);
            }
      
            .icon {
              color: inherit;
            }
      
          }
      
          .buttonIcon {
            @media (min-width: 450px) and (max-width: 650px) {
              display: none !important;
            }
            @media (min-width: 850px) {
              display: none;
            }
          }
          .buttonText {
            @media(max-width: 450px) {
              display: none;
            }
            @media(min-width: 650px) and (max-width: 850px) {
              display: none;
            }
          }
        }
      }
      .ant-card-body {
        .signature {
          display: flex;
          flex-direction: row;
          justify-content: end;
          color: #d1d0d0;
          font-style: italic;
          font-size: 12px;
      
          .buttonModal {
            text-decoration: underline;
            cursor: pointer;
            margin: 0 3px;
            transition-property: all;
            transition-duration: 300ms;
      
            &:hover {
              color: #1777ff;
            }
          }
        }
      }
    }

  }

}
