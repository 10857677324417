.tasksPage {

  &__header {
    display: flex;
    justify-content: space-between;

    &_title {
      margin: 0!important;
    }
  }

  &__projectItem {
    overflow: scroll;
    height: calc(100% - 15px);
    // background-color: rgba(0, 0, 0, 0.02);
  }
}

.tasks {
  &__wrapperTitle {
    display: flex;
    font-display: row;

    .ant-spin-spinning {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &-button {
      border-radius: 4px !important;
    }
  }

  .ant-collapse-header {
    background: #EAF8D7 !important;
    border: 1px solid #EAF8D7;
    border-radius: 0;
  }

  .ant-collapse {
    border: none;
  }

  .ant-collapse-item {
    margin-bottom: 20px;
  }

  .ant-collapse-content-box {
    padding: 0 !important;
    border: none;
  }

  .ant-collapse-content {
    border: none;
  }

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > td,
  .ant-table tfoot > tr > th {
    padding: 10px;
    font-size: 12px;
  }

  &__accordion {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: -5px;
  }

  &-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    &__title {
      margin: 0 !important;
    }

    &__item {
      margin: 0;
      text-transform: capitalize;
      font-size: 12px;
      @media screen and (max-width: 1280px) {
        font-size: 10px;
      }

      &-estimation {
        margin: 0 5px;
        color: black;
      }

      &:nth-child(1) {
        width: 15%;
      }

      &:nth-child(2) {
        width: 60%;
        @media screen and (max-width: 1280px) {
          width: 63%;
        }
      }

      &:nth-child(3) {
        width: 15%;
        text-align: right;
        @media screen and (max-width: 1280px) {
          width: 12%;
        }
      }

      &:nth-child(4) {
        width: 10%;
        text-align: right;
      }
    }
  }

  &__field {
    width: 100%;
    margin: 10px 0;
  }

  &__pagination {
    display: flex;
    align-items: center;

    &-item {
      display: block;
      padding: 0 6px;
      color: rgba(0, 0, 0, 0.85);
      transition: none;
      outline: none;
      background-color: #fff;
      border: 1px solid #d9d9d9;
      cursor: pointer;
      border-radius: 2px;
      list-style: none;
      text-align: center;
      width: 32px;
      height: 32px;
      margin-right: 8px;
      line-height: 30px;
    }

    &-item:hover {
      border-color: #1890ff;
      color: #1890ff;
    }

    &-item-active {
      font-weight: 500;
      background: #fff;
      border-color: #1890ff;
      color: #1890ff;
    }
  }

  &__link {
    background: transparent;
    border: none;
    outline: none;
    color: #1890ff;
    text-decoration-color: #1890ff;
    text-decoration-style: solid;
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.done {
  color: green;
}

.in_progress {
  color: blue;
}

.rejected {
  color: red;
}

.errorText {
  color: tomato;
  font-size: 12px;
  font-style: italic;
  margin: 0 20px;
}


