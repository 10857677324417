.dayoff {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  &__date {
    margin: 0;

    @media (min-width: 620px) {
      padding-left: 10px;
    }

    @media screen and (max-width: 500px) {
      font-size: 12px;
    }
  }

  &__filter {
    &_input {
      width: 100% !important;
      margin-bottom: 10px !important;
    }
    &_checkbox {
      display: flex!important;
      align-items: center!important;
      margin-bottom: 10px !important;
    }
  }
}

.dayoffPage {
  &__wrapper-table {
    width: 100%;
    overflow: scroll; 

  @media (min-width: 620px) {
    table {
      tbody {

        .ant-table-cell {
          padding: 0 8px !important;

          .wrapper-cell {
            padding: 8px 0;

            .ant-typography mark {
              background-color: transparent;
            }
          }

          .isToday {
            // background-color: #ffe58f !important;
            // background-color: #ffe58f73!important;
            background-color: rgb(215, 250, 224) !important;

          }
        }
  
      }
    }
  }

  



  }


}



