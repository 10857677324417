.removeWrapper {
	position: relative;

	.untouchable {
		position: absolute;
		background-color: hsl(0deg 0% 100% / 78%);
		width: 100%;
		height: 100%;
		z-index: 100;
	}

	.hidden {
		display: none;
	}

	.overUntouchable {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 101;
	}

	// .visible

	.wrapperCancelButton {
		align-items: end;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: end;
		padding: 26px 16px;
	}

}
  