.header {
  width: 100vw;
  box-shadow: 0 5px 5px 0 rgba(50, 50, 50, 0.2);
  background-color: #ffffff;
  position: fixed;
  top: 0;
  z-index: 1000;

  &__inner {
    width: 90%;
    margin: 0 auto;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 900px) {
      width: calc(100% - 30px);
      padding: 10px 0;
    }
  }

  &__profile {
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__drawerButton {
    font-size: 20px;
    @media screen and (min-width: 901px) {
      display: none;
    }
  }

  &__logo {
    height: 30px;
    width: auto;
    @media screen and (max-width: 900px) {
      height: 20px;
    }
  }

  &__navLinks {
    margin-top: 10px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  &__navLink {
    text-transform: capitalize;
    color: gray;
    text-decoration: none;
    padding-bottom: 10px;

    &_arrow {
      // margin: 0 0 8px 10px;
      margin: 0 0 10px 5px;
    }

    &_arrow, &_arrowRight {

      svg {
        // fill: hsl(207.37deg 78.8% 57.45% / 55%);
        fill: rgb(181 187 193 / 55%);
        transition-property: fill;
        transition-duration: 300ms;

        &:hover, &:active {
          fill: #3d9ae8c7;
        }
      }
    }
  }

  &__navLinkActive {
    text-transform: capitalize;
    color: black;
    padding-bottom: 9px;
    text-decoration: none;
    border-bottom: 1px solid lightblue;
  }

  &__dropdownBtn {
    display: flex;
    cursor: pointer;
    align-items: center;
  }

  &__dropdownLink {
    min-width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.drawer {
  &__user {
    display: flex;
    align-items: center;

    &_name {
      margin-left: 10px;
      flex-grow: 1;
    }

    &_closeIcon {
      cursor: pointer;
    }
  }

  &__navLinks {
    display: flex;
    flex-direction: column;
  }

  &__navLink {
    text-transform: capitalize;
    color: gray;
  }

  &__navLinkActive {
    text-transform: capitalize;
    color: #1890ff;
  }

  &__divider {
    margin: 10px 0 !important;
  }
}
