.infrastructureBlock {
	.ant-card {
		.ant-card-body {
			padding: 0 10px 10px 10px !important;
		}
	}
	.purple {
		.ant-card {
			.ant-card-head {
				background-color: rgba(128,0,128,.078);
			}

			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px rgba(246,237,246,.5);
			}
		}
	}

	.yellow {
		.ant-card {
			.ant-card-head {
				background-color: rgb(255 254 10 / 18%);
			}

			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px rgb(253 252 228 / 67%);
			}
		}
	}

	.green {
		.ant-card {
			.ant-card-head {
				background-color: rgb(31 205 82 / 8%);
			}

			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px rgb(241 251 242);
			}
		}
	}

	.red {
		.ant-card {
			.ant-card-head {
				background-color: rgb(255 0 0 / 8%);
			}
			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px hsl(0deg 56% 96% / 47%);
			}
		}
	}

	.blue {
		.ant-card {
			.ant-card-head {
				background-color: rgb(99 209 255 / 16%);
			}
			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px #e9f7ffbd;
			}
		}
	}

	.brown {
		.ant-card {
			.ant-card-head {
				background-color: rgb(118 58 0 / 6%);
			}
			.ant-card-body {
				box-shadow: inset 0 -7px 25px 5px #6333000a;
			}
		}
	}

	.wrapperButtons {
		display: flex;
		flex-direction: row;
		justify-content: end;
		gap: 6px;

		.ant-btn {
			border: none;
			padding: 5px 10px;
			border-radius: 0;
			transition-property: all;
			transition-duration: 300ms;
			// color: #cfcfcf;
			background-color: transparent;
			// color: #a5a5a5;
			color: #0000002b;

			&:hover {
				// color: #1777ff;
				// background-color: white;
				color: #686868;
				background-color: #ffffffa3;
				// box-shadow: 1px 1px 4px 3px #0101011f;
				box-shadow: 1px 1px 5px 1px rgba(1,1,1,.1);
				transform: scale(1.07);
			}

			.restore {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
			}

			.icon {
				color: inherit;
			}

		}

		.buttonIcon {
			@media (min-width: 450px) and (max-width: 650px) {
				display: none !important;
			}
			@media (min-width: 850px) {
				display: none !important;
			}
		}
		.buttonText {
			@media(max-width: 450px) {
				display: none;
			}
			@media(min-width: 650px) and (max-width: 850px) {
				display: none;
			}
		}
	}

	.secondSection {
		display: flex;
		flex-direction: row;
		border-top: 1px solid #f0f0f0;
	}

	.firstSection {
		display: flex;
		flex-direction: column;

		.content  {
			display: flex;
			flex-direction: column;

			border-top: 1px solid #f0f0f0;
		}
		// .wrapperButtons {
		//   display: flex;
		//   flex-direction: row;
		//   justify-content: end;

		//   .buttonIcon {
		//     @media(min-width: 450px) {
		//       display: none !important;
		//     }
		//     @media(min-width: 650px) and (max-width: 750px) {
		//       display: none;
		//     }
		//   }
		//   // .buttonText {
		//   //   @media(max-width: 450px) {
		//   //     display: none;
		//   //   }
		//   //   @media(min-width: 650px) and (max-width: 750px) {
		//   //     display: none;
		//   //   }
		//   // }
		// }
	}
	.firstSectionFooter {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0;


		.wrapperSignatures {
			display: flex;
			flex-direction: column;
		}
	}

	.signature {
		display: flex;
		flex-direction: row;
		justify-content: end;
		color: #d1d0d0;
		font-style: italic;
		font-size: 12px;
		@media(min-width: 650px) and (max-width: 900px) {
			flex-direction: column;
			align-items: end;
		}
		@media (max-width: 350px) {
			flex-direction: column;
			align-items: end;
		}

		.buttonModal {
			text-decoration: underline;
			cursor: pointer;
			margin: 0 3px;
			transition-property: all;
			transition-duration: 300ms;

			&:hover {
				color: #1777ff;
			}
		}
	}
}