.listItem {
  &__user {
    color: #3c9ae8;
    cursor: pointer;

    &:hover {
      color: #7ec1ff;
    }
  }

  &__info {
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
      color: #3c9ae8!important;
    }
  }
}

.dayoffMain {

  &__wrapper {
    .ant-list {
      border: none;
      box-shadow: 1px 1px 10px rgb(0 0 0 / 9%);
      border-radius: 8px;

      .ant-col:last-child {
        display: flex;
        justify-content: end;
      }
    }

    .ant-list-item {
      // padding: 8px 24px;
      padding: 6px 24px;
      border-block-end: 1px solid rgba(5, 5, 5, 0.02);

      &:last-child {
        padding-bottom: 8px;
      }

      @media screen and (min-width: 1240px) and (max-width: 1340px) {
        padding-inline: 15px;
      }
      @media screen and (min-width: 990px) and (max-width: 1240px) {
        padding-inline: 10px;
      }
      @media screen and (max-width: 300px) {
        padding-inline: 15px;
      }

      .name {
        @media screen and (min-width: 992px) and (max-width: 1120px) {
          font-size: 13px;
        }
      }
    }
  }

  &__date {
    @media screen and (min-width: 992px) and (max-width: 1120px) {
      font-size: 11px;
    }
    @media screen and (max-width: 625px) {
      font-size: 12px;
    }
  }
}
