.workedInPast {
	.ant-collapse-content-box {
		padding: 0 !important;
	}

	.ant-collapse {
		border: 1px solid #f0f0f0;

		.ant-collapse-content {
			border-top: 1px solid #e9e9e9;
		}
	}

}