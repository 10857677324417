.main {
  &__month {
    @media screen and (max-width: 575px) {
      // margin-bottom: 10px!important;
      margin-bottom: 5px!important;
      text-align: center!important;
    }

    &_datePicker {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0 8px;
      width: 30px;
      border: none;
      cursor: pointer;
      color: rgb(52 154 204 / 25%);

      &:hover {
        color: #349acc8c;
        
      }

      .ant-picker-input {
        color:inherit;

        input {
          display: none;
        }

        .ant-picker-suffix {
          margin-inline-start: 0px;
          color: inherit;
          margin: 4px 0;

          .anticon svg {
            height: 20px;
            width: 20px;
          }
        }


      }

    }
    &_arrow {
      color: #349acc !important;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }
    }
    &_title {
      color: #349acc !important;
      margin: 0 auto !important;
      width:186px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.container {
  margin: 102px 4% 0;
  // @media (min-width:450px) and (max-width: 990px) {}

  @media (max-width:450px) {
    margin: 85px 4% 0;
  }
}
