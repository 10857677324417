// .events {
.customTable {

	.ant-table-cell {
		padding: 5px !important;

		.ant-card {
			width: 100%;
			border: none;
			border-radius: 0;
			background-color: transparent;
			// padding: 12px 0;
			padding: 8px 0 20px 0;

			.ant-card-head {
				border-bottom: 1px solid transparent;
			}
			.ant-card-body {
				padding: 0 10px !important;
			}
		}
	}

	.wrapperDate {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

}

.holidaysType {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  max-width: 1000px;
  width: 100%;


  // tr>th {
  //   padding: 5px 10px !important;
  // }
  td.ant-table-cell {
    padding: 2px !important;
  }
  .ant-table-cell {
    .ant-card {
      padding: 0px 0 10px;

      .ant-card-head {
        height: 38px !important;
        padding: 0 10px !important;
      }    
    }
  }
}