.hours {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_title {
      display: flex;
      align-items: center;
      margin-right: 20px;
      margin-bottom: 0;
    }

    &_date {
      margin: 5px 0 0 10px;
      font-size: 20px;
      color: #3c9ae8;
      @media screen and (max-width: 900px) {
        font-size: 16px;
      }
    }

    &_datePicker {
      width: 100%;
    }
  }

  &__item {
    margin: 15px 0;
    position: relative;
    padding: 30px 15px 15px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    box-shadow: 2px 2px 4px 0 rgba(34, 60, 80, 0.2);
    min-height: 279px;

    &_section {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    &_remove {
      font-size: 18px;
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
  }

  &__modal {
    &_maxHours {
      text-align: right;
      @media screen and (max-width: 575px) {
        text-align: left;
      }
    }
    &_phase {
      color: #3c9ae8;
    }
  }

  &__closed_item {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 223px;
  }

  &__closed_item_elem {
    margin-top: 5px;
  }

  &__closed_item_elem_comment {
    min-height: 60px;
  }

  // &__item_wrapper {
  //   position: relative;
  // }

  // &__untouchable {
  //   position: absolute;
  //   background-color: hsl(0deg 0% 100% / 78%);
  //   width: 100%;
  //   height: 100%;
  //   z-index: 100;
  // }

  // &__over_untouchable {
  //   position: absolute;
  //   width: 100%;
  //   height: 100%;
  //   z-index: 101;
  // }

  // &__hidden {
  //   display: none;
  //   // visibility: hidden;
  // }

  // &__wrapper_cancel_button {
  //   align-items: end;
  //   display: flex;
  //   flex-direction: column;
  //   height: 100%;
  //   justify-content: end;
  //   padding: 26px 16px;
  // }
}
