.contacts {
  &__filters {
    display: flex;
    justify-content: space-between;

    &_input {
      width: 100% !important;
      @media screen and (max-width: 768px) {
        margin-bottom: 10px!important;
      }
    }

    &_select {
      width: 100%;
    }

    &_button {
      width: 100%;
    }

    &_buttonText {
      @media screen and (max-width: 1100px) {
        display: none !important;
      }
    }

    &_buttonIcon {
      margin: 0 !important;
      @media screen and (min-width: 1100px) {
        display: none !important;
      }
    }
  }
}

.contactDetail__modalContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.fullInfo {
  p {
    font-size: 17px;
    margin-bottom: 7px;
  }
}
.contact__card {
  padding: 150px;
}

.contacts_item__table {
  box-shadow: 1px 1px 15px rgb(0 0 0 / 6%);
  padding: 10px 0;

  &:hover {
    box-shadow: rgba(0,0,0,0.2) 1px 1px 15px !important;
  }
}

.contacts_item {
  @media(max-width: 450px) {
    .ant-card-head {
      padding: 0 10px;

      .ant-space {
        margin: 2px 0 8px 0 !important;
      }
    }

  }

  .ant-card-body {
    padding: 10px 24px !important;

    @media (max-width: 450px) {
      padding: 6px 12px !important;

      .ant-table-wrapper {

        .ant-table-cell {
          padding: 6px 6px !important;
        }
      }
    }
  }

  .ant-space {
    width: 100%;

    @media (max-width: 400px) {
      gap: 15px !important;
    }
  }

  .ant-space-item:last-child {
    width: 100%;
  }
}

.contact_item__title_with_table {
  h3 {
    padding: 5px 8px;
  }

  .ant-divider {
    margin: 12px 0 0 !important;

  }
}

.wrapperSelectsWithReset {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  transition-property: all;
  transition-duration: 300ms;

  @media (max-width: 450px) {
    display: flex;
    flex-direction: column;
  }

  .wrapperSelects {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 70%;
    transition-property: all;
    transition-property: 400ms;

    @media(max-width: 450px) {
      width: 100%;
    }
  }

  .ant-btn {
    width: 30%;
    transition-property: all;
    transition-property: 400ms;
    @media(max-width: 450px) {
      width: 100%;
    }
  }

}

.ant-modal-content {
  @media(max-width: 450px) {
    padding: 20px 18px !important;

    .ant-modal-title {
      .ant-space {
        // gap: 15px !important;
        gap: 6px!important;
      }
    }

    .ant-descriptions-view {
      .ant-descriptions-item-label {
        padding: 6px 12px !important;
      }
    }

  }

}
