.container {
  margin: 102px 5% 0;

  min-height: calc(100vh - 182px);
  padding-bottom: 30px;
  // overflow: scroll;
  @media screen and (max-width: 900px) {
    margin: 67px 15px 0;
    min-height: calc(100vh - 117px);
  }

  .eventHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: min-content ;
    font-weight: 600;
    font-size: 15px;
    color: black;
    background-color: #d7fae0;
    padding: 10px 30px;
    margin-bottom: 20px;
    // background: linear-gradient(to right, white, #D7FADE, #d7fae0, #d7fae0, #d7fae0, #d7fae0, #fff);
    background: linear-gradient(to right, white, #d2ffc7, #fff);

    @media (max-width: 450px) {
      margin-bottom: 15px;
      padding: 5px 0;
      background: none;
    }



    .eventWrapper {
      // background-color: #fffea5;
      background-color: #fffeb5;
      padding: 5px;
      border-radius: 5px;
      // border: 1px solid #89b795;
      box-shadow: 1px 2px 14px -3px #91c4c7;
      display: flex;
      align-items: center;
      gap: 7px;
      color: #507559;
      padding: 5px 15px;

      @media (max-width: 450px) {
        gap: 4px;
        padding: 7px 10px;
        box-shadow: 0px 2px 10px 4px #50c68757;
        border: 1px solid #9abfa34f;
        font-size: 13px;

        svg {
          min-width: 28px;
          min-height: 28px;
        }
      }

    }

    .eventDate {
      display: flex;
      align-items: center;
      // background-color: #fffea5;
      // padding: 5px;
      // border-radius: 5px;
      // border: 1px solid #89b795;
    }
  }
}

.eventContainer {
  margin: 70px 5% 0 !important;

  @media(max-width: 900px) {
    margin: 43px 15px 0 !important;
  }
}
