.forgot-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  @media screen and (max-width: 900px) {
    min-height: calc(100vh - 50px);
  }

  &__container {
    display: flex;
    justify-content: center;
    padding: 0 10px;
  }

  &__title {
    color: #069 !important;
    margin-top: 10px;
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &__item {
      margin: 10px 0;
      border-radius: 4px;
    }
  }
}
