.events {

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.filters {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 10px;
		margin: 20px 0;

		.ant-input {
			// width: 50% !important;
			width: 100% !important;


			// @media(max-width: 900px) {
			// 	width: 100% !important;
			// }
		}

	}

}

.ant-modal {
	
	.event, .project {
		.ant-form {
			display: flex;
			flex-direction: column;
			
			.ant-form-item {
				margin-bottom: 15px;

				.ant-row {
					display: flex;
					flex-direction: column;
					gap: 2px;

		
					.ant-form-item-label {
						display: flex;
						flex-direction: row;
						justify-content: start;
					}

					.ant-form-item-control {
						flex: 0;
					}
		
				}
			}
		}
	}

	.wrapperButton {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
}
