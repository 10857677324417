.profileLink {
  display: flex;
  align-items: center;
  cursor: pointer;

  &__name {
    margin-left: 20px;
    align-self: center;
  }
}

.ant-dropdown-menu {
  max-width: 250px;
  left: 40px;
}
