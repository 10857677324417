.projects {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    margin: 27px 0 10px;



    @media (max-width: 450px) {
      flex-direction: column;

      .checkbox-with-reset {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .ant-checkbox-wrapper {
          margin-left: 3px;
        }
      }

      input {
        width: 100% !important;
        margin: 7px 15px !important;
      }
    }

    &_input {
      width: 30% !important;
      margin-right: 15px!important;
    }

    &_checkbox {
      flex-grow: 1;
    }
  }

  &__advancedFilter {
    margin-bottom: 10px;

    &_wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px 10px;

      @media (min-width: 320px) and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 7px 10px;
      }
      @media (max-width: 320px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 7px 10px;
      }

      .filter {
        // border: 1px solid;

        p {
          color: #676767;
        }

        &:last-child {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }

        .ant-select, .ant-picker {
          width: 100% !important;
        }

        .ant-checkbox-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 100%;
        }

      }

      .isNotSelected {
        .ant-select .ant-select-selection-item, .ant-picker .ant-picker-input input {
          color: rgba(0, 0, 0, 0.25) !important;
        }

        
      }

      .isSelected {
        .ant-select-selector, .ant-picker {
          // border: 1px solid #b3b3b3;
          border: 1px solid #5480c06e;
          box-shadow: 1px 1px 5px 0px #1777ff61;
        }
      }
    }

    .headerPanel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 27px;

      .amountFilters {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        width: 25px;
        height: 25px;
        color: white;
        font-size: 15px;
        border-radius: 100px;

        // background-color: #1777ff87;
        background-color: rgb(29 132 255 / 78%);

        border: 1px solid transparent;
      }
    }
  }
}

.projectFilter__reset {
  margin-left: 3px;
  @media (min-width: 450px) and (max-width: 768px) {
    display: none !important;
  }
}

.ant-table-cell {
  p {
    display: flex;
    flex-direction: row;

    .wrapperSvg {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}

